import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'

import Loader from '@/components/Loader/Loader'

import { store } from '@/store/store'

import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  /*  <React.StrictMode> */
  <Suspense fallback={<Loader />}>
    <BrowserRouter>
      <Provider store={store}>
        <YMInitializer accounts={[90838528]} options={{ webvisor: true }} />
        <App />
      </Provider>
    </BrowserRouter>
  </Suspense>
  /*  </React.StrictMode> */
)

reportWebVitals()
